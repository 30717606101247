import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container>
      <div class="section">
        <h2>1. General Information</h2>
        <p>
          This policy applies to the website operating at the URL: <strong>nowoczesnawitryna.pl</strong>.
        </p>
        <p>
          The operator of the site and the data controller is: <strong>Dariusz Wasilewski, Bogoczowiec 4c/9</strong>.
        </p>
        <p>
          Operator’s contact email address: <a href="mailto:nowoczesnawitryna@gmail.com">nowoczesnawitryna@gmail.com</a>
        </p>
        <p>The Operator is the administrator of personal data provided voluntarily in the service.</p>
        <p>The service uses personal data for the following purposes:</p>
        <ul>
          <li>Handling inquiries via the contact form</li>
          <li>Presentation of offers or information</li>
        </ul>
        <p>The service collects information about users and their behavior through:</p>
        <ul>
          <li>Data entered in forms that are sent to the Operator’s systems.</li>
          <li>Saving cookies on users' devices.</li>
        </ul>
      </div>

      <div class="section">
        <h2>2. Selected Data Protection Methods Used by the Operator</h2>
        <ul>
          <li>Login areas and data entry points are protected by SSL certificates.</li>
          <li>Regular backup procedures are in place.</li>
          <li>Software used by the Operator is regularly updated.</li>
        </ul>
      </div>

      <div class="section">
        <h2>3. Hosting</h2>
        <p>
          The service is hosted by <strong>cyberFolks.pl</strong>, which maintains server logs to ensure technical
          reliability. Logs may include:
        </p>
        <ul>
          <li>Addresses of requested resources (pages, files)</li>
          <li>Timestamp of request and response</li>
          <li>HTTP error information</li>
          <li>Referer link (previous page)</li>
          <li>Browser information and IP address</li>
        </ul>
      </div>

      <div class="section">
        <h2>4. Your Rights and Additional Information on Data Use</h2>
        <p>
          The Administrator may transfer data to other recipients if required to perform a contract or fulfill legal
          obligations. You can request:
        </p>
        <ul>
          <li>Access to your data</li>
          <li>Correction, deletion, or restriction of processing</li>
          <li>Data portability</li>
        </ul>
        <p>
          You have the right to object to data processing for certain purposes and to file a complaint with the
          President of the Personal Data Protection Office.
        </p>
      </div>

      <div class="section">
        <h2>5. Information in Forms</h2>
        <p>
          The service collects information voluntarily provided by the user, including personal data that may be used
          for handling service requests, business contact, or service registration.
        </p>
      </div>

      <div class="section">
        <h2>6. Administrator’s Logs</h2>
        <p>Information on user behavior may be logged for service administration purposes.</p>
      </div>

      <div class="section">
        <h2>7. Important Marketing Techniques</h2>
        <p>
          The Operator uses Google Analytics to analyze traffic on the site. Users can manage their cookie preferences
          via{' '}
          <a href="https://www.google.com/ads/preferences/" target="_blank" rel="noopener noreferrer">
            Google Ads Preferences
          </a>
          .
        </p>
      </div>

      <div class="section">
        <h2>8. Information on Cookies</h2>
        <p>The service uses cookies to:</p>
        <ul>
          <li>Maintain user sessions (after logging in)</li>
          <li>Implement marketing techniques</li>
        </ul>
        <p>Users can change cookie settings in their web browser.</p>
      </div>

      <div class="section">
        <h2>9. Managing Cookies – How to Grant and Withdraw Consent?</h2>
        <p>Instructions for specific browsers:</p>
        <ul>
          <li>Edge</li>
          <li>Internet Explorer</li>
          <li>Chrome</li>
          <li>Safari</li>
          <li>Firefox</li>
          <li>Opera</li>
        </ul>
        <p>Mobile devices:</p>
        <ul>
          <li>Android</li>
          <li>Safari (iOS)</li>
          <li>Windows Phone</li>
        </ul>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
