import * as React from 'react';
import '../styles/global.scss';
import '../styles/normalize.scss';
import '../styles/typography.scss';
import '../styles/settings.scss';
import RootLayout from '../components/RootLayout/RootLayout';
import Headers from '../components/Headers/Headers';
import Footer from '../components/Footer/Footer';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

const PolitykaPrywatnosci = () => {
  const title = 'privacy policy';
  const subtitle = 'feel secure online';
  const siteTitle = 'privacy policy - Modern Website';
  const siteDescription =
    'This website uses cookies. We use cookies to personalize content and ads, provide social media features, and analyze traffic.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/privacy-policy/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/polityka-prywatnosci/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title} subtitle={subtitle} active="blog" />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PolitykaPrywatnosci;
